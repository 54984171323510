import React from "react"
import { graphql } from "gatsby"
import PageBuilder from "@/components/pageBuilder"
import Seo from "../components/seo"
import imageUrlBuilder from "@sanity/image-url"
import clientConfig from "../../client-config"
// import Layout from "@/components/layout"

export const query = graphql`
  query PageTemplateQuery($id: String!) {
    page: sanityPages(id: { eq: $id }) {
      id
      ...PageBuilderPages
      title
      excerpt
      mainImage {
        asset {
          url
        }
      }
      slug {
        current
      }
    }
  }
`

const PageTemplate = props => {
  const { data } = props
  const page = data && data.page
  const { pageBuilder, _rawPageBuilder } = page

  const builder = imageUrlBuilder(clientConfig.sanity)
  function urlFor(source) {
    return builder.image(source)
  }

  return (
    <div className="bg-white dark:bg-zinc-900 ">
      <Seo
        title={page.title + " - Budmen"}
        description={page.excerpt}
        defer={false}
        image={
          page.mainImage
            ? urlFor(page.mainImage)
                .width(1200)
                .height(628)
                .auto("format")
                .url()
            : "https://cdn.sanity.io/images/rbhjt84j/production/32881103f500654a2573256baf9e2be661847bb5-750x750.png"
        }
        twitterImage={
          page.mainImage
            ? urlFor(page.mainImage)
                .width(1200)
                .height(628)
                .auto("format")
                .url()
            : "https://cdn.sanity.io/images/rbhjt84j/production/32881103f500654a2573256baf9e2be661847bb5-750x750.png"
        }
      />
      <PageBuilder
        pageBuilder={pageBuilder}
        _rawPageBuilder={_rawPageBuilder}
      />
    </div>
  )
}

export default PageTemplate
